import { css } from '@compiled/react';
import React from 'react';

import { token } from '@atlaskit/tokens';
import Spinner from '@atlaskit/spinner/spinner';

import { LoadableLazy } from '@confluence/loadable';

const spinnerContainerStyles = css({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '32px',
	width: '32px',
	marginRight: token('space.150'),
});

const LoadingSpinner = () => (
	<div css={spinnerContainerStyles}>
		<Spinner />
	</div>
);

export const LazyNavigation = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-Navigation" */ './Navigation'))
			.NavigationPresenterMode,
	loading: () => <LoadingSpinner />,
});

export const LazySettingsMenu = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-SettingsMenu" */ './SettingsMenu')).SettingsMenu,
	loading: () => <LoadingSpinner />,
});
