import type { FC } from 'react';
import React from 'react';

import Flag from '@atlaskit/flag';
import ErrorIcon from '@atlaskit/icon/core/error';

type HeaderPresentationModeProps = {
	title: string;
};

export const ErrorFlag: FC<HeaderPresentationModeProps> = ({ title }) => {
	return (
		<Flag
			appearance="error"
			icon={<ErrorIcon spacing="spacious" label="Error" />}
			id="error"
			key="error"
			title={title}
		/>
	);
};
