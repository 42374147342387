import React, { useCallback, useContext, useMemo, useState } from 'react';
import fscreen from 'fscreen';

import {
	PRESENTER_MODE_FULLSCREEN_API_EXPERIENCE,
	PRESENTER_MODE_VIEW_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { useThemingState } from '@confluence/theming/entry-points/useThemingState';

import { PresenterModeWrapper } from './PresenterModeWrapper';

type PresenterModeContextType = {
	isPresenterModeActive: boolean;
	isBrowserFullscreen: boolean;
	isPresenterModeEnabled: boolean;
	enterPresenterMode: () => void;
	exitPresenterMode: () => void;
	enterBrowserFullscreen: () => void;
	enterSystemFullscreen: () => void;
	presenterModeContextError: Error | null;
	setPresenterModeContextError: React.Dispatch<React.SetStateAction<any>>;
	setIsBrowserFullscreen: React.Dispatch<React.SetStateAction<boolean>>;
};
const PresenterModeContext = React.createContext<PresenterModeContextType>({
	isPresenterModeActive: false,
	isBrowserFullscreen: false,
	presenterModeContextError: null,
	isPresenterModeEnabled: false,
	enterPresenterMode: () => {
		// Intentional no-op
	},
	exitPresenterMode: () => {
		// Intentional no-op
	},
	setPresenterModeContextError: () => {
		// Intentional no-op
	},
	enterBrowserFullscreen: () => {
		// Intentional no-op
	},
	enterSystemFullscreen: () => {
		// Intentional no-op
	},
	setIsBrowserFullscreen: () => {
		// Intentional no-op
	},
});

type PresenterModeContextProviderProps = {
	children: React.ReactNode;
	contentId: string;
	spaceKey: string;
};
/**
 * This component not only exposes the PresenterModeContext to any child consumers,
 * it also is responsible for rendering the PresenterModeWrapper itself, which is
 * placed directly next to the child element passed in.
 */
export const PresenterModeContextProvider = ({
	children,
	contentId,
	spaceKey,
}: PresenterModeContextProviderProps) => {
	const [isPresenterModeActive, setIsPresenterModeActive] = useState(false);
	const [isBrowserFullscreen, setIsBrowserFullscreen] = useState(false);
	const [presenterModeContextError, setPresenterModeContextError] = useState(null);
	const experienceTracker = useContext(ExperienceTrackerContext);
	const [, { restoreThemePreference }] = useThemingState();

	const enterBrowserFullscreen = useCallback(() => {
		setIsBrowserFullscreen(true);
	}, []);

	const handleFullScreenApiError = useCallback(
		(error: any) => {
			enterBrowserFullscreen();
			setPresenterModeContextError(error);
		},
		[setPresenterModeContextError, enterBrowserFullscreen],
	);

	const fscreenRequestFullscreen = useCallback(() => {
		return Promise.resolve(fscreen.requestFullscreen(document.documentElement));
	}, []);

	const fscreenExitFullscreen = useCallback(() => {
		return Promise.resolve(fscreen.exitFullscreen());
	}, []);

	const isPresenterModeEnabled = fscreen.fullscreenEnabled;

	const enterPresenterMode = useCallback(() => {
		experienceTracker.start({
			name: PRESENTER_MODE_FULLSCREEN_API_EXPERIENCE,
		});

		fscreen.addEventListener('fullscreenerror', handleFullScreenApiError, false);

		setIsPresenterModeActive(true);
		setPresenterModeContextError(null);

		if (!isPresenterModeEnabled) {
			const error = new Error('Fullscreen mode is not supported');
			handleFullScreenApiError(error);
			enterBrowserFullscreen();
			return;
		}

		if (fscreen.fullscreenElement) {
			fscreenExitFullscreen()
				.then(fscreenRequestFullscreen)
				.then(() => {
					experienceTracker.succeed({
						name: PRESENTER_MODE_VIEW_EXPERIENCE,
					});
				})
				.catch(enterBrowserFullscreen);
		} else {
			fscreenRequestFullscreen()
				.then(() => {
					experienceTracker.succeed({
						name: PRESENTER_MODE_VIEW_EXPERIENCE,
					});
				})
				.catch(enterBrowserFullscreen);
		}
	}, [
		experienceTracker,
		fscreenRequestFullscreen,
		isPresenterModeEnabled,
		fscreenExitFullscreen,
		handleFullScreenApiError,
		enterBrowserFullscreen,
	]);

	const enterSystemFullscreen = useCallback(() => {
		setIsBrowserFullscreen(false);
		enterPresenterMode();
	}, [enterPresenterMode]);

	const exitPresenterMode = useCallback(() => {
		setIsPresenterModeActive(false);
		fscreen.removeEventListener('fullscreenerror', handleFullScreenApiError, false);
		void restoreThemePreference();
	}, [setIsPresenterModeActive, handleFullScreenApiError, restoreThemePreference]);

	const data = useMemo(
		() => ({
			isPresenterModeActive,
			isBrowserFullscreen,
			enterPresenterMode,
			exitPresenterMode,
			isPresenterModeEnabled,
			presenterModeContextError,
			setPresenterModeContextError,
			enterBrowserFullscreen,
			setIsBrowserFullscreen,
			enterSystemFullscreen,
		}),
		[
			isPresenterModeActive,
			isBrowserFullscreen,
			enterPresenterMode,
			exitPresenterMode,
			isPresenterModeEnabled,
			presenterModeContextError,
			setPresenterModeContextError,
			enterBrowserFullscreen,
			setIsBrowserFullscreen,
			enterSystemFullscreen,
		],
	);

	return (
		<PresenterModeContext.Provider value={data}>
			{children}
			<PresenterModeWrapper contentId={contentId} spaceKey={spaceKey} />
		</PresenterModeContext.Provider>
	);
};

export const usePresenterModeContext = () => {
	return useContext(PresenterModeContext);
};

type PresenterModeContextConsumerProps = {
	children: (enterPresenterMode: () => void) => React.ReactNode;
};

export const PresenterModeContextConsumer = ({ children }: PresenterModeContextConsumerProps) => {
	const { enterPresenterMode } = usePresenterModeContext();
	return children(enterPresenterMode);
};
